<template>
	<h1><i class="bi bi-slash-circle"></i> FORBIDDEN</h1>
    <p class="mt-3">このページを操作する権限がありません</p>
</template>

<script>
export default {
    name: 'PageError403',
}
</script>

<style scoped>

</style>
